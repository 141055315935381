<template>
  <aside>
    <div
      v-if="alertsSorted.length === 0"
      class="text--secondary py-5 px-5 no-recent-alerts"
    >
      <v-icon>fa-check-circle</v-icon>
      <small>{{ $t("no_recent_alarms") }}</small>
    </div>

    <v-list v-else dense>
      <template v-for="(a, i) in alertsSorted">
        <v-list-item :key="a.id" @click="openAlertDetails(a)">
          <v-list-item-content>
            <alert :alert="a" :margin="2" open-details show-site-name></alert>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="i !== alertsSorted.length - 1"
          :key="`${a.id}-divider`"
        ></v-divider>
      </template>
    </v-list>
  </aside>
</template>

<script>
import { orderBy } from "lodash-es";
import { mapState } from "vuex";
import Alert from "@/components/Global/Alert";
import EventBus from "@/services/EventBus";

export default {
  components: { Alert },
  computed: {
    ...mapState({ alerts: (state) => state.alert.alerts }),
    alertsSorted() {
      return orderBy(this.alerts, ["date"], ["desc"]);
    },
  },
  methods: {
    openAlertDetails(alert) {
      EventBus.$emit("search-site", alert.site, true);
    },
  },
};
</script>
